import React, { useCallback, useContext, useState } from "react"
import { Button, Checkbox, App, Popconfirm, Space, Spin, Input, Typography, Table, TableColumnsType, Tag, Tooltip, Alert } from "antd"
import { generatePath, useHistory, useParams } from "react-router-dom"
import { ClinicChecklistLogData, ClinicChecklistLogValues, useClinicChecklistLog, useClinicSetChecklistLogMissed, useUpdateClinicChecklistLog } from "../../api"
import { IssuesCloseOutlined, CheckCircleOutlined, CloseCircleOutlined, UserOutlined, MedicineBoxOutlined, PlayCircleOutlined, AimOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { ClinicAuthTokenContext } from "."
import { ClinicRecordNavigationState } from "../../components/Flow/Record"
import Linkify from "react-linkify"

const { Title, Text } = Typography
const { TextArea } = Input

const CHECKED_COLOR = "rgb(43,52,45)"

const Checklist: React.FC = () => {
    const clinicAuthTokenContext = useContext(ClinicAuthTokenContext);
    const { studyId, checklistId } = useParams<{ studyId: string, checklistId: string }>()
    const { data: checklistLog } = useClinicChecklistLog(clinicAuthTokenContext.token, studyId, checklistId)
    const history = useHistory()
    const { message: messageApi } = App.useApp();
    const [initials, setInitials] = useState<Map<string, string>>(new Map())

    const [updateChecklist] = useUpdateClinicChecklistLog({
        onSuccess: () => {
            void messageApi.success('Checklist updated!')
        },
        onError: () => {
            void messageApi.error('Error updating checklist!')
        }
    })

    const [setChecklistMissed] = useClinicSetChecklistLogMissed({
        onSuccess: () => {
            void messageApi.success('Checklist updated!')
        },
        onError: () => {
            void messageApi.error('Error updating checklist!')
        }
    })

    const updateLogValue = useCallback((checklistLogData: ClinicChecklistLogValues[], checkListId: string) => {
        void updateChecklist({ token: clinicAuthTokenContext.token, studyId, checklistLogId: checkListId, values: checklistLogData })
    }, [])

    const columns: TableColumnsType<ClinicChecklistLogData> = [
        {
            title: "Activity",
            dataIndex: "display_name",
            key: "display_name",
            width: "40%",
            render: function name(_, row) {
                return (
                    <div style={{ width: "100%" }}>
                        <Space direction='horizontal'>
                            <Text><Linkify
                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                    <a target="_blank" rel="noreferrer" href={decoratedHref} key={key}>
                                        {decoratedText}
                                    </a>
                                )}
                            >{row.display_name}</Linkify></Text>
                        </Space>
                    </div>
                )
            }
        },
        {
            title: "Completed",
            dataIndex: "checked",
            key: "checked",
            width: "20%",
            align: 'center',
            render: function completed(_, row, index) {
                const CompleteCheckbox = () => (
                    <Checkbox defaultChecked={row.checked} onChange={({ target }) => {
                            if (checklistLog && row.checked !== target.checked) {
                                const newValues = checklistLog.data.map(step => ({ step_id: step.step_id, checked: step.checked, notes: step.notes }))
                                newValues[index].checked = target.checked
                                updateLogValue(newValues, checklistLog.id)
                            }
                    }}>Complete</Checkbox>
                )

                if (row.step_type === "survey") {
                    const clinicianAdministered = row.meta?.['clinician_administered'] === "true";
                    return (
                        <Space direction="vertical">
                            {row.checked && (
                                <div>
                                    <CheckCircleOutlined />
                                    &nbsp;
                                    Survey Completed
                                </div>
                            )}
                            {clinicianAdministered ? (
                                <Tag color="error" icon={<MedicineBoxOutlined />}>Clinician Administered</Tag>
                            ) : (
                                <Tag color="warning" icon={<UserOutlined />}>Self Administered</Tag>
                            )}
                            <Button size="large" type="primary" onClick={() => {
                                if (row.checked && !confirm("This visit step is already completed. Are you sure you want to retake this survey?")) return

                                history.push(generatePath(`/clinic/:studyId/checklist/:checklistId/survey/:stepId${clinicianAdministered ? '' : '/user'}`, { studyId, checklistId, stepId: row.step_id }))
                            }}>{row.checked ? "Retake" : "Take"} {row.meta?.['data_stream_name']}</Button>
                        </Space>
                    )
                }

                if (row.step_type === "session") {
                    if (row.meta?.['session_mode'] === "instructions" || row.meta?.['session_mode'] === "practice") {
                        const tasksUrl = row.meta?.['tasks_url']
                        return tasksUrl && (
                            <Space direction="vertical">
                                {row.checked && (
                                    <div>
                                        <CheckCircleOutlined />
                                        &nbsp;
                                        Completed
                                    </div>
                                )}
                                <Button
                                    icon={row.meta?.['session_mode'] === "instructions" ? <InfoCircleOutlined /> : <AimOutlined />}
                                    size="large"
                                    type="default"
                                    href={tasksUrl}
                                    target="_blank"
                                    onClick={() => {
                                        if (!checklistLog || row.checked) return

                                        const newValues = checklistLog.data.map(step => ({ step_id: step.step_id, checked: step.checked, notes: step.notes }))
                                        newValues[index].checked = true
                                        updateLogValue(newValues, checklistLog.id)
                                    }}
                                >
                                    {row.meta?.['experiment_display_name'] || row.meta?.['experiment_name']} {row.meta?.['session_mode'] === "instructions" ? "Instructions" : "Practice"}
                                </Button>
                            </Space>
                        )
                    }

                    return (
                        <Space direction="vertical">
                            {row.checked && (
                                <div>
                                    <CheckCircleOutlined />
                                    &nbsp;
                                    Flow {/* TODO: take from data stream */} Recording Completed
                                </div>
                            )}
                            <Space direction="horizontal">
                                <Tooltip title="Initials are required to record data">
                                    <Text style={{ whiteSpace: 'nowrap' }}><Text type="danger">*</Text>Initials</Text>
                                </Tooltip>
                                <Input
                                    size="large"
                                    required
                                    value={initials.get(row.step_id)}
                                    onChange={e => {
                                        const newMap = new Map(initials);
                                        newMap.set(row.step_id, e.target.value)
                                        setInitials(newMap)
                                    }}
                                />
                            </Space>
                            <Button icon={<PlayCircleOutlined />} disabled={!initials.get(row.step_id)} size="large" type="primary" onClick={() => {
                                if (row.checked && !confirm("This visit step is already completed. Are you sure you want to rerun this session?")) return
                                
                                history.push(generatePath('/clinic/:studyId/flow', { studyId }), {
                                    clinicToken: clinicAuthTokenContext.token,
                                    checklistLogId: checklistLog?.id,
                                    checklistLogStepId: row.step_id,
                                    createdByClinic: initials.get(row.step_id),
                                    studyId,
                                    virtualUserId: checklistLog?.virtual_user_id,
                                    meta: row.meta
                                } as ClinicRecordNavigationState)
                            }}>{row.checked ? "Rerun" : "Run"} {row.meta?.['experiment_display_name'] || row.meta?.['experiment_name']}</Button>
                        </Space>
                    )
                }

                return <CompleteCheckbox />
            }
        },
        {
            title: "Notes",
            dataIndex: "notes",
            key: "notes",
            width: "40%",
            render: function notes(_, row, index) {
                return (
                    <Space direction="vertical" style={{ width: "100%" }}>
                        {row.warnings.map((warning, i) => (
                            <Alert type='warning' showIcon key={i} message={warning} />
                        ))}
                        {(() => {
                            const couplingPerPlate = row.metrics?.percent_channels_retained?.extra_values?.percent_channels_retained_per_plate;
                            if (!couplingPerPlate) return

                            const highPriorityCoupling = ((couplingPerPlate.Frontoparietal || 0) + (couplingPerPlate.L_Temporal || 0) + (couplingPerPlate.R_Temporal || 0)) / 3.0;
                            const rating = highPriorityCoupling >= 81 ? 3 : highPriorityCoupling >= 41 ? 2 : highPriorityCoupling >= 16 ? 1 : 0;
                            switch (rating) {
                                case 0:
                                    return <Alert type='error' showIcon message={<><b>High-Priority Modules:</b> Lowest Signal</>} />
                                case 1:
                                    return <Alert type='warning' showIcon message={<><b>High-Priority Modules:</b> Low Signal</>} />
                                case 2:
                                    return <Alert type='info' showIcon message={<><b>High-Priority Modules:</b> High Signal</>} />
                                case 3:
                                    return <Alert type='success' showIcon message={<><b>High-Priority Modules:</b> Highest Signal</>} />
                            }
                        })()}
                        {(() => {
                            switch (row.metrics?.motion_score?.rating) {
                                case 0:
                                    return <Alert type='success' showIcon message={<><b>Motion:</b> Excellent</>} />
                                case 1:
                                    return <Alert type='info' showIcon message={<><b>Motion:</b> Good</>} />
                                case 2:
                                    return <Alert type='warning' showIcon message={<><b>Motion:</b> Please Review</>} />
                                case 3:
                                    return <Alert type='error' showIcon message={<><b>Motion:</b> Bad</>} />
                            }
                        })()}
                        <TextArea size="large" defaultValue={row.notes} style={{ width: "100%", height: "100px" }} onBlur={({ target }) => {
                            if (checklistLog && row.notes !== target.value) {
                                const newValues = checklistLog.data.map(step => ({ step_id: step.step_id, checked: step.checked, notes: step.notes }))
                                newValues[index].notes = target.value
                                updateLogValue(newValues, checklistLog.id)
                            }
                        }} />
                    </Space>
                )
            }
        }
    ]

    const finishVisit = () => history.push(generatePath('/clinic/:studyId', { studyId }));
    const surveyOrSessionNotFinishedCount = (checklistLog?.data || []).filter(step => 
        !step.checked && 
        (step.step_type === "survey" || step.step_type === "session") &&
        step.meta?.['session_mode'] !== "instructions" &&
        step.meta?.['session_mode'] !== "practice"
    ).length

    return !checklistLog ? <Spin /> : (
        <Space direction="vertical" size="large" style={{ minWidth: 800 }}>
            <Space direction="horizontal" size="large">
                <Title level={3} type="secondary">{checklistLog.protocol}</Title>
                <Title level={3} type="secondary" code>{checklistLog.participant_id}</Title>
                <Title level={3}>
                    {checklistLog.missed ? <IssuesCloseOutlined title="Missed" /> : checklistLog.complete ? <CheckCircleOutlined title='Complete' /> : <CloseCircleOutlined title='Not Complete' />}
                    &nbsp;
                    {checklistLog.name}
                </Title>
            </Space>
            <Table
                columns={columns}
                dataSource={checklistLog.data}
                pagination={false}
                rowKey="step_id"
                onRow={record => record.checked ? { style: { backgroundColor: CHECKED_COLOR } } : {}}
            />
            <Space direction="horizontal" size="large">
                {surveyOrSessionNotFinishedCount > 0 ? (
                    <Popconfirm title={`${surveyOrSessionNotFinishedCount} Survey(s) or Flow Recording(s) not yet completed. Are you sure you want to Finish Visit?`} onConfirm={finishVisit}>
                        <Button size="large" type="primary">Finish Visit</Button>
                    </Popconfirm>
                ) : (
                    <Button size="large" type="primary" onClick={finishVisit}>Finish Visit</Button>
                )}
                <Popconfirm title={`Are you sure you want to mark as ${checklistLog.missed ? "not" : ""} missed?`} onConfirm={() => void setChecklistMissed({ token: clinicAuthTokenContext.token, studyId, checklistLogId: checklistLog.id, missed: !checklistLog.missed })}>
                    <Button size="large" danger>Mark {checklistLog.missed ? "Not" : ""} Missed</Button>
                </Popconfirm>
            </Space>
        </Space>
    )
}

export default Checklist