import { Modal, Space, Tooltip, Typography, Table, Button } from 'antd';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { ChecklistStepType, Step, useAllDataStreams, useChecklist, useExperiments } from '../api'
import { AimOutlined, PlayCircleOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { ColumnsType } from 'antd/lib/table';

const { Text } = Typography

export const ChecklistPreview: React.FC<{checklistId: string}> = ({checklistId}) => {
  const { organizationId, studyId } = useParams<{ organizationId: string, studyId: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: checklist } = useChecklist(organizationId, studyId, checklistId)
  const { data: dataStreams } = useAllDataStreams(organizationId, studyId)
  const { data: experiments } = useExperiments(organizationId, studyId)

  const columns: ColumnsType<Step> = [
    {
      title: "Name",
      dataIndex: "display_name",
      key: "name",
      width: "50%",
      render: function name(display_name) {
        return (
          <div style={{ width: "100%" }}>
            <Space direction='horizontal'>
              <Text>{display_name}</Text>
            </Space>
          </div>
        )
      }
    },
    {
      title: "Type",
      dataIndex: "step_type",
      key: "step_type",
      width: "50%",
      render: function stepType(stepType: ChecklistStepType, row) {
        if (stepType === "session") {
          const experiment = experiments?.find(experiment => experiment.name === row["experiment_name"])
          return (
            <Space size="small">
              <Text strong>Session:</Text>
              {
                row["session_mode"] === "instructions" ? <Tooltip title="Show Instructions"><InfoCircleOutlined /></Tooltip> :
                row["session_mode"] === "practice" ? <Tooltip title="Practice"><AimOutlined /></Tooltip> :
                <PlayCircleOutlined />
              }
              <Text>{experiment ? (experiment.display_name || experiment.name) : row["experiment_name"]}</Text>
            </Space>
          )
        } else if (stepType === "survey") {
          const survey = dataStreams?.find(dataStream => dataStream.id === row["data_stream_id"])
          return (
            <Space size="small">
              <Text strong>Survey:</Text>
              <Text>{survey?.name}</Text>
            </Space>
          )
        }
      }
    },
  ]
      
  return (
    <>
      <Button type="text" onClick={() => setIsModalVisible(true)}>View Preview</Button>
      <Modal zIndex={1031} title={checklist?.name} onCancel={() => setIsModalVisible(false)} open={isModalVisible} footer={[
        <Button type='primary' key="submit" onClick={() => setIsModalVisible(false)}>
          Ok
        </Button>]}>
        <Table size="small" dataSource={checklist?.steps} columns={columns} rowKey="id" />
      </Modal>
    </>
  )
}