import { Alert, Button, Card, App, Select, Space, Typography } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { generatePath, useHistory, useParams } from "react-router-dom"
import { useClinicRegister, useClinicRegisterInfo } from "../../api"
import { SurveyStepForm } from "../../components/Survey/types"
import Taker from "../../components/Survey/Taker"
import { ClinicAuthTokenContext } from "."

const { Title, Paragraph } = Typography

const Register: React.FC = () => {
    const history = useHistory()
    const { studyId } = useParams<{ studyId: string }>();
    const clinicAuthTokenContext = useContext(ClinicAuthTokenContext);
    const [participantId, setParticipantId] = useState<string>("")
    const { data: clinicRegisterInfo } = useClinicRegisterInfo(clinicAuthTokenContext.token, studyId)

    const [surveyDone, setSurveyDone] = useState<boolean>();
    const [surveyForm, setSurveyForm] = useState<SurveyStepForm>({});
    const [protocolId, setProtocolId] = useState<string>()
    const { message: messageApi } = App.useApp();

    const [clinicRegister, { isLoading: clinicRegisterLoading }] = useClinicRegister({
        onError: () => {
            void messageApi.error('Error registering!')
        }}) 

    useEffect(() => {
        if (!clinicRegisterInfo?.enrollment_survey) {
            setSurveyForm({})
            setSurveyDone(true)
        }
    }, [clinicRegisterInfo?.enrollment_survey])

    useEffect(() => {
        if (!protocolId && clinicRegisterInfo) {
            setProtocolId(clinicRegisterInfo.protocols.length === 1 ? clinicRegisterInfo.protocols[0].id : "")
        }
    }, [protocolId, clinicRegisterInfo])

    return (
        <Space direction="vertical">
            {!participantId ? (<>
                <Title level={1} style={{ fontFamily: "Museo" }}>Registration</Title>
                {clinicRegisterInfo?.enrollment_survey?.steps[0].questions && (
                    <>
                        <Paragraph>Ask the participant for the below info to register them in the study</Paragraph>
                        <Taker
                            questions={clinicRegisterInfo.enrollment_survey.steps[0].questions}
                            onChange={(done, form) => {
                                setSurveyForm(form)
                                setSurveyDone(done)
                            }}
                            allQuestionsRequired={true}
                        />
                    </>
                )}

                {clinicRegisterInfo && clinicRegisterInfo.protocols.length > 1 && (
                    <Card title="Cohort" style={{ marginTop: 20, marginBottom: 20 }}>
                        <Select size="large" value={protocolId} onChange={newValue => setProtocolId(newValue)} style={{ width: 200 }}>
                            {clinicRegisterInfo.protocols.map(protocol => (
                                <Select.Option key={protocol.id} value={protocol.id}>{protocol.name}</Select.Option>
                            ))}
                        </Select>
                    </Card>
                )}

                <Button type="primary" size="large" loading={clinicRegisterLoading} disabled={!surveyDone} onClick={async () => {
                    const res = await clinicRegister({ token: clinicAuthTokenContext.token, studyId, survey: surveyForm, protocolId })
                    if (res && res.participant_id) {
                        setParticipantId(res.participant_id)
                    }
                }}>Register Participant</Button>
            </>) : (<>
                <Title level={1} style={{ fontFamily: "Museo" }}>Registration Successful</Title>

                <Alert type="warning" showIcon message="Remember to log the Participant ID in a safe place!" />

                <Card
                    title="Your new Participant ID"
                    actions={[
                        <Button key="return" size="large" onClick={() => history.push(generatePath('/clinic/:studyId', { studyId }))}>
                            Return to Home
                        </Button>
                    ]}
                >
                    <Title style={{ margin: 0 }} level={3} code>{participantId}</Title>
                </Card>
            </>)}
        </Space>
    )
}

export default Register