import { MedicineBoxOutlined, UnorderedListOutlined, UserOutlined } from "@ant-design/icons"
import { captureException } from "@sentry/react"
import { Alert, App, Button, Card, Divider, Popconfirm, Result, Space, Spin, Tooltip } from "antd"
import React, { useContext, useEffect, useState } from "react"
import { generatePath, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { ClinicAuthTokenContext } from "."
import { useClinicSurvey, useClinicSurveySubmit } from "../../api"
import Taker from "../../components/Survey/Taker"
import { SurveyStepForm } from "../../components/Survey/types"
import { AuthForm } from "./Auth"

const Survey: React.FC = () => {
    const clinicAuthTokenContext = useContext(ClinicAuthTokenContext);
    const { studyId, checklistId, stepId } = useParams<{ studyId: string, checklistId: string, stepId: string }>()
    const { data: dataStream } = useClinicSurvey(clinicAuthTokenContext.token, studyId, checklistId, stepId)
    const { path } = useRouteMatch()

    const [surveyDone, setSurveyDone] = useState<boolean>();
    const [surveyForm, setSurveyForm] = useState<SurveyStepForm>({});
    const [success, setSuccess] = useState<boolean>()
    const [nextSurvey, setNextSurvey] = useState<{
        stepId: string,
        name: string,
        clinicianAdministered: boolean,
    }>()
    const history = useHistory()
    const { message: messageApi } = App.useApp();
    const [showReturnToVisit, setShowBackToVisit] = useState<boolean>(false)

    const [clinicSurveySubmit, { isLoading: clinicSurveySubmitLoading }] = useClinicSurveySubmit({
        onError: () => {
            void messageApi.error('Error submitting survey!')
        }})

    useEffect(() => {
        // every new step we reset ...
        setSurveyDone(false)
        setSurveyForm({})
        setSuccess(false)
        setNextSurvey(undefined)
    }, [stepId])

    return (
        success ? (
            <Result
                status="success"
                title="Successfully submitted survey!"
                extra={nextSurvey && !nextSurvey.clinicianAdministered ? (
                    <Card title="Next Survey" extra={
                        <Tooltip title="Back to Visit">
                            <Button onClick={() => setShowBackToVisit(!showReturnToVisit)} icon={<UnorderedListOutlined />} />
                        </Tooltip>
                    }>
                        <Space direction="vertical" size="large" style={{ width: '100%' }}>
                            <Button size="large" block type="primary" onClick={
                                () => history.push(generatePath(`/clinic/:studyId/checklist/:checklistId/survey/:stepId/user`, { studyId, checklistId, stepId: nextSurvey.stepId }))
                            }>Take {nextSurvey.name}</Button>

                            {showReturnToVisit && (
                                <>
                                    <Divider />

                                    <AuthForm
                                        studyId={studyId}
                                        label='Back to Visit'
                                        onToken={() => clinicAuthTokenContext.unlockFromParticipant?.(
                                            generatePath('/clinic/:studyId/checklist/:checklistId', { studyId, checklistId })
                                        )}
                                    />
                                </>
                            )}
                        </Space>
                    </Card>
                ) : (
                    path.endsWith("/user") ? 
                    <AuthForm
                        studyId={studyId}
                        label={`${nextSurvey && nextSurvey.clinicianAdministered ? 'The next survey is clinician-administered. ' : ''}Please hand the device back to the operator`}
                        onToken={() => clinicAuthTokenContext.unlockFromParticipant?.(
                            nextSurvey && nextSurvey.clinicianAdministered ?
                            generatePath(`/clinic/:studyId/checklist/:checklistId/survey/:stepId`, { studyId, checklistId, stepId: nextSurvey.stepId }) :
                            generatePath('/clinic/:studyId/checklist/:checklistId', { studyId, checklistId })
                        )}
                    /> :
                    <Button onClick={() => history.push(generatePath("/clinic/:studyId/checklist/:checklistId", { studyId, checklistId }))} icon={<UnorderedListOutlined />}>Back to Visit</Button>
                )}
            />
        ) :
        !dataStream?.meta ? <Spin /> :
        <Space direction="vertical" size="large" style={{ minWidth: 800 }}>

            {path.endsWith("/user") ? (
                <Alert type="warning" showIcon icon={<UserOutlined />} message="Self Administered" />
            ) : (
                <Alert type="error" showIcon icon={<MedicineBoxOutlined />} message="Clinician Administered" description={
                    <Space direction="horizontal">
                        Cancel survey and
                        <Popconfirm
                            title="Back to Visit"
                            description="Are you sure you want to cancel the survey?"
                            onConfirm={() => history.push(generatePath("/clinic/:studyId/checklist/:checklistId", { studyId, checklistId }))}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button icon={<UnorderedListOutlined />}>Back to Visit</Button>
                        </Popconfirm>
                    </Space>
                } />
            )}

            <Taker
                questions={(dataStream.meta.steps || [])[0].questions}
                onChange={(done, form) => {
                    setSurveyForm(form)
                    setSurveyDone(done)
                }}
                allQuestionsRequired={dataStream.meta.questionsRequired}
            />

            <Button type="primary" size="large" block loading={clinicSurveySubmitLoading} disabled={!surveyDone} onClick={async () => {
                try {
                    const res = await clinicSurveySubmit({ token: clinicAuthTokenContext.token, studyId, checklistLogId: checklistId, stepId, answers: surveyForm })
                    if (res?.success) {
                        setSuccess(true)
                        if (res.next_step_id && res.next_step_survey_name) {
                            setNextSurvey({
                                stepId: res.next_step_id,
                                name: res.next_step_survey_name,
                                clinicianAdministered: !!res.next_clinician_administered,
                            })
                        }
                    } else {
                        throw new Error(`Failed to submit survey: ${JSON.stringify(res)}`)
                    }
                } catch (e) {
                    captureException(e)
                }
            }}>Submit Survey</Button>
        </Space>
    )
}

export default Survey