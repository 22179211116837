import { captureException } from "@sentry/react";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { App, Button, Collapse, ConfigProvider, Empty, Layout, Result, Space, Spin, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useEnroll, useEnrollSubmit, useSurvey, useSurveySubmit } from "../../api";
import { KernelLogoPlain, LogoSeparator } from "../../components/Navigation";
import Taker from "../../components/Survey/Taker";
import { SurveyStepForm } from "../../components/Survey/types";

const { Text, Title } = Typography

const Survey: React.FC = () => {
    const { message: messageApi } = App.useApp();
    const [surveyDone, setSurveyDone] = useState<boolean>();
    const [surveyForm, setSurveyForm] = useState<SurveyStepForm>({});
    const [success, setSuccess] = useState<boolean>();

    const { token, studyId } = useParams<{ token?: string, studyId?: string }>()

    const { data: enroll, isLoading: enrollLoading } = useEnroll(studyId)
    const { data: survey, isLoading: surveyLoading } = useSurvey(token)

    const [enrollSubmit, { isLoading: enrollSubmitLoading }] = useEnrollSubmit({
        onError: () => {
            void messageApi.error('Error enrolling!')
        }})
    const [surveySubmit, { isLoading: surveySubmitLoading }] = useSurveySubmit({
        onError: () => {
            void messageApi.error('Error submitting survey!')
        }})

    return (
        <ConfigProvider
            theme={{
                token: {
                    fontSize: 16,
                },
            }}
        >
            <Layout className='layout'>
                <Layout.Header className='site-layout-header'>
                    <Space>
                        <KernelLogoPlain />
                        <LogoSeparator />
                        <Title level={4} style={{ margin: 0, marginLeft: 15, fontFamily: 'Museo' }} ellipsis>
                            {
                                studyId && enroll ? enroll.name :
                                token && survey ? survey.name :
                                undefined
                            }
                        </Title>
                    </Space>
                </Layout.Header>
                <Layout.Content style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 20 }}>
                    { success ? <Result
                        status="success"
                        title={
                            studyId && enroll ? "Thanks for submitting the enrollment survey. We will be in contact if you are eligible for the study!" :
                            token && survey ? "Survey submitted successfully!" :
                            undefined
                        }
                    /> :
                      (enrollLoading || surveyLoading) ? <Spin size="large" /> :
                        studyId && enroll ? (
                            <Space direction="vertical" size="large">
                                {enroll.description && (
                                    <Collapse
                                        size="small"
                                        items={[{ key: 'description', label: 'Description', children: <MarkdownPreview source={enroll.description} /> }]}
                                    />
                                )}
                                <Taker
                                    questions={(enroll.enrollment_survey.steps || [])[0].questions}
                                    onChange={(done, form) => {
                                        setSurveyForm(form)
                                        setSurveyDone(done)
                                    }}
                                    allQuestionsRequired={true}
                                />
                                <Button type="primary" size="large" block loading={enrollSubmitLoading} disabled={!surveyDone} onClick={async () => {
                                    try {
                                        const res = await enrollSubmit({ studyId, survey: surveyForm })
                                        if (res?.success) {
                                            setSuccess(true)
                                        } else {
                                            throw new Error(`Failed to submit enroll: ${JSON.stringify(res)}`)
                                        }
                                    } catch (e) {
                                        captureException(e)
                                    }
                                }}>Enroll In Study</Button>
                            </Space>
                        ) : token && survey?.meta?.steps ? (
                            <Space direction="vertical" size="large">
                                <Taker
                                    questions={survey.meta.steps[0].questions}
                                    onChange={(done, form) => {
                                        setSurveyForm(form)
                                        setSurveyDone(done)
                                    }}
                                    allQuestionsRequired={survey.meta.questionsRequired}
                                />
                                <Button type="primary" size="large" block loading={surveySubmitLoading} disabled={!surveyDone} onClick={async () => {
                                    try {
                                        const res = await surveySubmit({ token, answers: surveyForm })
                                        if (res?.success) {
                                            setSuccess(true)
                                        } else {
                                            throw new Error(`Failed to submit survey: ${JSON.stringify(res)}`)
                                        }
                                    } catch (e) {
                                        captureException(e)
                                    }
                                }}>Submit Survey</Button>
                            </Space>
                        ) :
                        <Empty description="Expired or Not Found" />
                    }
                </Layout.Content>
                <Layout.Footer style={{ textAlign: 'center', paddingTop: 10, paddingBottom: 10 }}><Text type="secondary">©{moment().format('YYYY')} kernel</Text></Layout.Footer>
            </Layout>
        </ConfigProvider>
    )
}

export default Survey